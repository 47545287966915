import React from "react";
import Board from "react-trello";
import ClickableRowData from "../../component/rowData/clickableRowData"
import { customUseSelector } from "../../store/customUseSelector";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { updateCells } from "../../store/table/tableThunk";
import { binarySearch } from "../../utility/storageHelpers";
import { updateKanban } from "../../api/filterApi";
import { updateFilterView } from "../../store/allTable/allTableSlice";
import { Typography } from "@mui/material";
import { getAllTableInfo } from "../../store/allTable/allTableSelector";


export default function KanbanView() {
  const dispatch = useDispatch();
  const rows = customUseSelector((state) => state.table.data);
  const { tableName, filterName, dbId } = useParams();
  const AllTableInfo = customUseSelector((state) => getAllTableInfo(state));
  const filterInfo = AllTableInfo.tables[tableName]?.filters?.[filterName];
  const tableData = customUseSelector((state) => state.tables.tables[tableName])
  const filterData = tableData.filters[filterName]
  const  stackBy = filterData.view.stackBy;
  const columns = customUseSelector((state) => state.table.columns);
  const filterFields = columns
  .filter((column) => column.metadata?.hide !== true) 
  .map((column) => column.id);
  const stackByField = customUseSelector(
      (state) => state.tables.tables[tableName].fields[stackBy]
    ) || [];
  const isUser = stackByField.fieldType === 'user';
  const orgUsers= customUseSelector((state) => state.dataBase.currentOrgUsers);
  const options = isUser ? Object.keys(customUseSelector((state)=>state.tables.userAcess) || {}) : filterData.view.stackOrder || [];

  const currentField = filterInfo.currentField;
  const order = filterInfo.order;

  const lanes = options.reduce((obj, key) => {
    obj[key] = [];
    return obj;
  }, {});

  for (let row of rows) {
    let filteredRow = filterFields.reduce((newRow, field)=>{
      newRow[field] = row[field];
      return newRow;
    }, {});

    if (row[stackBy] && lanes[row[stackBy]]) {
      lanes[row[stackBy]].push({
        heading: row.autonumber+'',
        value: filteredRow,
        id : row.autonumber,
        sort: row[currentField],
        laneId : row[stackBy],
        sx : {
          transform: "scale(0.85)",
          maxWidth: 280,
          border: 'none',
          backgroundColor: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.20) 2.4px 2.4px 3.2px'
        },
      });
    }
  }

  function laneSortFunction(card1, card2) {
    
    const value1 = card1.sort;
    const value2 = card2.sort;
    if (value1 == null || value2 == null) return 0;
    let comparison = 0;
    if (typeof value1 === "number" && typeof value2 === "number") {
      comparison = value1 - value2;
    } else {
      comparison = String(value1).localeCompare(String(value2), undefined, { numeric: true, sensitivity: 'base' });
    }
    return order === 'asc' ? comparison : -comparison;
  }

  function onCardMoveAcrossLanes (prevLane, newLane, autonumber){
    dispatch(updateCells({
      updatedArray : [{
        where : 'autonumber='+autonumber,
        fields : {
          [stackBy] : isUser ? [newLane] : newLane 
        }
      }], 
      indexIdMapping: {[autonumber]: binarySearch(rows, autonumber, (a, b)=> a.autonumber - b)}
    }))
  }

  async function handleLaneDragEnd(prevLane, newLane){
    if(isUser) return;
    const updatedView = await updateKanban(dbId, tableName, filterName, {prevLane, newLane});
    const view = updatedView.data.data.view;
    dispatch(updateFilterView({tableName, filterName, view}));
  }
  if(!options.length){
    return (
      <Typography align='center' variant = 'h5'>Add some options to {stackByField.fieldName} for this view.</Typography>
    )
  }
  return (
    <Board
      components = {{
        Card : ClickableRowData
      }}
      data={{
        lanes: Object.entries(lanes).map(([laneId, cards]) => {
          const laneName = isUser ? orgUsers[laneId]?.name : laneId
          return {
            cards,
            title: laneName,
            label:(cards?.length || 0),
            id: laneId,
            style: {
              width: 290,
              backgroundColor: '#e9ecef',
              maxHeight:'74vh'
            }
          }}),
      }}
      draggable
      collapsibleLanes
      laneSortFunction={laneSortFunction}
      onCardMoveAcrossLanes={onCardMoveAcrossLanes}
      handleLaneDragEnd={handleLaneDragEnd}
      style={{
        backgroundColor: "#fff",
        height: '74vh'
      }}
    />
  );
}
