import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import RowData from "./rowData"
import RowPopup from "../table/rowPopup/rowPopup";
import { updateUrlParam } from "../../table/utils";

function ClickableRowData(props) {
  const querystring = new URLSearchParams(window.location.search);
  const rowId = querystring.get('row');
  const [clicked, setClicked] = useState(false);
  useEffect(()=>{
    if(rowId && !clicked && (parseInt(props.heading)==rowId)) setClicked(true);
  },[rowId])
  function onClick(){
    updateUrlParam('row',parseInt(props.heading))
    setClicked(true);
  }

  function onClose(){
    updateUrlParam('row')
    setClicked(false);
  }

  return (
    <>
      <Box onClick={onClick} sx={{cursor:"pointer"}}>
        <RowData {...props}/>
      </Box>
      {clicked && <RowPopup rowData={{ ...props.value , autonumber : parseInt(props.heading) }} closePopup={onClose}/>}
    </>
  );
}

ClickableRowData.propTypes = {
  row: PropTypes.bool,
  heading: PropTypes.string,
  value : PropTypes.any,
  sx : PropTypes.object
};


export default ClickableRowData;
